import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';

import { ROUTE_ANIMATIONS_ELEMENTS, NotificationService } from '@app/core';
import { MainService } from '@app/core/main.service';
import { MatPaginator, MatSort } from '@angular/material';

@Component({
  selector: 'fidelizacion-vencimientos',
  templateUrl: './vencimientos.component.html',
  styleUrls: ['./vencimientos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VencimientosComponent implements OnInit {
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  displayedColumns: string[] = ['fecha','puntos'];

  @ViewChild(MatSort) sort: MatSort;

  constructor(public asm: MainService, private noti: NotificationService, private cd: ChangeDetectorRef) {
    this.datos
  }

  datos : any;
  puntosProxVto : any;
  proxVto : string;
  ngOnInit() {

    this.asm.post("Vencimientos_Listar",undefined).subscribe((ret) => {
      this.datos = ret.movimientos;
      this.puntosProxVto = ret.puntosProxVto;
      this.proxVto = ret.proxVto;
      this.cd.markForCheck();
    },
      (err) => {
        this.asm.requesting--; 
        if (err.error && err.error.Mensaje)
          this.noti.warn(err.error.Mensaje);
      }
    )


  }
}
