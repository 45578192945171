import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Fidelizaciones } from './modelo/modelo.negocio';

@Injectable()
export class MainService {
    //////////////////////////////////////////////////////////////////////////////////////
    
   //public serverUrl: string = 'http://localhost:5988/';
    public serverUrl: string = 'https://apisfractal.mazalosa.com.ar/';
    public version: string = '10'; 
    public programa : number = 1;// FPRO CODIGO DE ESTE FRONTEND
    //////////////////////////////////////////////////////////////////////////////////////

    public lastUrl: string;
    apiPath: string;
    requesting: number = 0;

    public usarPageTransitions: boolean = false;

    public usuario: Fidelizaciones;
    public access_token = undefined;
    PLEASE_WAIT: string;
    NONE: string;

    sysevent: EventEmitter<EventosDeSistem> = new EventEmitter();
public mostrarMensajeEmailValidado : boolean  = false;
public mostrarMensajeEmailNoValidado : boolean  = false;
public esInvitacion : boolean  = false;

    constructor(public http: HttpClient,
    ) {
        this.apiPath = this.serverUrl + 'api/Mobile';
        this.access_token = localStorage.getItem('access_token');
    }


    get(endpoint: string, params?: any, reqOpts?: any) {

        //  this.requesting++;

        if (!reqOpts) {
            reqOpts = {
                params: new HttpParams()
            };
        }

        // Support easy query params for GET requests
        if (params) {
            reqOpts.params = new HttpParams();
            for (let k in params) {
                reqOpts.params = reqOpts.params.set(k, params[k]);
            }
        }

        return this.http.get(this.apiPath + '/' + endpoint, reqOpts);//.pipe(tap(() => { this.requesting--; if (this.requesting < 0) { this.requesting = 0; } }));
    }

    requestStack = [];
    requestCount: number = 0;
    failedRequestStack = [];
    post(endpoint: string, body: any, reqOpts?: any, anularLoader?: boolean) {

        let request_id = this.requestCount++;
        this.requestStack.push({ endpoint: endpoint, id: request_id });

        if (!anularLoader) {
            this.requesting++;
        }

        if (this.access_token) {
            var h = this.crearHeadersAuth();
            if (reqOpts) {
                reqOpts.headers = h;
            }
            else {
                reqOpts = {
                    headers: h
                }
            }
        }
        else {
            var h2 = new HttpHeaders();
            h2 = h2.set('version', this.version);
            if (reqOpts) {
                reqOpts.headers = h2;
            }
            else {
                reqOpts = {
                    headers: h2
                }
            }

        }

        this.lastUrl = this.apiPath + '/' + endpoint;
        return this.http.post(this.apiPath + '/' + endpoint, body, { headers: h, observe: "body" }).pipe(tap((ret: any) => { this.requesting--; if(this.requesting <0){this.requesting=0;} }));

    }

    getParam(nombreParam: string) {
        return this.get("getParam/" + nombreParam);
    }

    crearHeadersAuth(): HttpHeaders {
        var headers = new HttpHeaders();
        headers = headers.set('Authorization', 'Bearer ' + this.access_token);
        headers = headers.set('Content-Type', 'application/json');
        headers = headers.set('Accept', 'application/json');
        headers = headers.set('version', this.version);
        return headers;
    }

    public headers(): HttpHeaders {
        var headers = new HttpHeaders();
        headers = headers.set('Authorization', 'Bearer ' + this.access_token);
        return headers;
    }

    logout() {
        this.access_token = undefined;
        localStorage.removeItem('access_token');
        this.sysevent.emit(EventosDeSistem.Logout);
    }

    login(username: string, pass: string) {

        this.requesting++;
        var headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
        headers = headers.set('version', this.version);
        this.lastUrl = this.serverUrl + 'frontend/token';
        console.log('login post');
        var x = this.http.post(this.serverUrl + 'frontend/token', "username=" + username + '|' + this.programa + "&password=" + pass, {
            headers: headers
        });

        return x.pipe(tap((ret: any) => {
            this.access_token = ret.access_token;
            this.sysevent.emit(EventosDeSistem.Login);
            localStorage.setItem('access_token', this.access_token);
            console.log('login tap');
        }));//.catch((err) => { return this._serverError(err) });
    }

    obtenerUsuariosDatos() {
        this.lastUrl = this.apiPath + '/Usuario_ObtenerDatos';
        console.log('usurios datos  post');
        // this.spinnerDialog.show("Login", this.PLEASE_WAIT, undefined, { overlayOpacity: 0.6 });
        return this.post('Usuario_ObtenerDatos', null, { headers: this.crearHeadersAuth() }, true)
            .pipe(tap((res: Fidelizaciones) => {
                console.log('usurios datos tap');
                // this.translateService.setDefaultLang('en');
                //  this.spinnerDialog.hide();
                //  this.translateService.use(res.lang);      
                this.usuario = res;

            }));
    }

    umbralLastRefresh: number = 600;//segundos
    lastRefresh: number = 99999;
    lastRefreshDesencadenado: boolean = false;


    notifyErrorToServer(msg) {
        try { this.post('Usuario_Registrar', { mensaje: msg }).subscribe(); } catch{ }
    }


    _serverError(err: any) {
        this.access_token = undefined;
        this.usuario = undefined;
        console.log('sever error:', err);  // debug
        if (err instanceof Response) {
            //    alert(JSON.stringify(err));
            return Observable.throw('backend server error');
            // if you're using lite-server, use the following line
            // instead of the line above:
            //return Observable.throw(err.text() || 'backend server error');
        }
        //alert(JSON.stringify(err));
        return Observable.throw(err || 'backend server error');
    }



}

export class auxLoginSignup {
    public codigo: number;
    public email: string;
    public nombre: string;
    public apellido: string;
    public nacimiento: string;
    public telfijo: string;
    public telmovil: string;
    public pais: string;
    public provincia: string;
    public localidad: string;
    public clave: string;
    public recibirNovedades: boolean;
    public documento: string;
    public lifeStyle: string;
    public motivoCompra: string;
    public programa: number;
    public canal : string;	
}

export enum EventosDeSistem{
    Login,
    Logout
}