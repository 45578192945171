import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';

import { ROUTE_ANIMATIONS_ELEMENTS, NotificationService } from '@app/core';
import { MainService } from '@app/core/main.service';
import { MatSort } from '@angular/material';

@Component({
  selector: 'fidelizacion-compras',
  templateUrl: './compras.component.html',
  styleUrls: ['./compras.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComprasComponent implements OnInit {
    routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
    displayedColumns: string[] = ['fecha','sucursal','comprobante', 'cantidad','precio'];
  

    @ViewChild(MatSort) sort: MatSort;
  
    constructor(public asm: MainService, private noti: NotificationService, private cd: ChangeDetectorRef) {
      this.datos
    }
  
    datos : any;
  ngOnInit() {
  
      this.asm.post("Compras_Listar",undefined).subscribe((ret) => {
        this.datos = ret;
        this.cd.markForCheck();
      },
        (err) => {
          this.asm.requesting--; 
          if (err.error && err.error.Mensaje)
            this.noti.warn(err.error.Mensaje);
        }
      )
    }
  }
  