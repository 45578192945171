import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationService } from '@app/core';
import { MainService } from '@app/core/main.service';
import { DialogoConfirmacionComponent } from '@app/shared/dialogo-confirmacion/dialogo-confirmacion';


@Component({
	selector: 'cupon-dialog',
	templateUrl: './cupon_dialog.component.html',
	styleUrls: ['./cupon_dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CuponDialogComponent implements OnInit {
	cupon;
	modo;
	saldoActual : number = 0;
	@Output() cuponAdquirido = new EventEmitter<any>();
	constructor(public asm: MainService, private noti: NotificationService, 
		private cd: ChangeDetectorRef, private dialog : MatDialog,
		public dialogRef: MatDialogRef<CuponDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {
			this.cupon = data.cupon;
			this.modo = data.modo;
			this.saldoActual = data.saldoActual;

	}
	ngOnInit() {
	}

	adquirir(cupon) {

		const dialogRef = this.dialog.open(DialogoConfirmacionComponent, {
			width: '350px',
			data: "Confirma la adquisición del cupón? Se descontarán los puntos de su saldo."
		   });
		   dialogRef.afterClosed().subscribe(result => {
			if(result) {
			 
				this.asm.post("Cupones_Adquirir", { cupo_CodigoAlfa: cupon.cupo_CodigoAlfa,cupo_Codigo: cupon.cupo_Codigo, cuge_Codigo: cupon.cuge_Codigo }).subscribe((ret) => {
					this.noti.success("Adquiriste el cupón " + cupon.cupo_CodigoAlfa + "!");
					this.dialogRef.close('adquirido');
				},
					(err) => {
						this.asm.requesting--;
						if (err.error && err.error.Mensaje)
							this.noti.warn(err.error.Mensaje);
					}
				)			

			}
		   });
	}

}


