import * as Modelo from './modelo.negocio'

export class _Accesos {
	iDrecurso : number;
	identificacion : string;
	
	padreUsuarios : Modelo.Usuarios;
	padreRecursos : Modelo.Recursos;


}
export class _Clientes {
	clie_Codigo : number;
	clie_CodigoMadre : number;
	vend_Codigo : number;
	vend_CodigoCobrador : number;
	pine_Codigo : number;
	sucu_Codigo : number;
	sucu_CodMasCompra : number;
	sucu_CodCompraActual : number;
	loca_Codigo : number;
	clie_CodigoAlfa : string;
	clie_Apellido : string;
	clie_Nombre : string;
	clie_CodigoPostal : string;
	clie_Fax : string;
	clie_mail : string;
	clie_Nacimiento : Date;
	clie_FechaAlta : Date;
	clie_FechaBaja : Date;
	clie_Tipo : string;
	clie_Clasif : string;
	clie_ClasifSec : string;
	clie_Contacto : string;
	clie_IVA : number;
	clie_CUIT : string;
	clie_TipoDoc : string;
	clie_NumeroDoc : number;
	clie_DomiTipo : string;
	clie_DomiNombre : string;
	clie_DomiAltura : number;
	clie_DomiPiso : string;
	clie_DomiCuerpo : string;
	clie_DomiDepto : string;
	clie_DomiBarrio : string;
	clie_DomiEntreCalles : string;
	clie_DomiUF : string;
	clie_DomiManzana : string;
	clie_DomiLote : string;
	clie_DomiLocalidad : string;
	clie_DomiProvincia : string;
	clie_DomiPais : string;
	clie_DomiGLN : string;
	clie_TEcomercial : string;
	clie_TEcelular : string;
	clie_TEparticular : string;
	clie_Sexo : string;
	clie_Orden : number;
	clie_RequierePIN : string;
	clie_Permiso : string;
	clie_Observaciones : string;
	
	padreVendedor : Modelo.Vendedores;
	padreClientes : Modelo.Clientes;
	padreSucursalMasCompra : Modelo.Sucursales;
	padreSucursalCompraActual : Modelo.Sucursales;
	padreSucursalDelCliente : Modelo.Sucursales;
	padreLocalidades : Modelo.Localidades;
	padrePINes : Modelo.PINes;
	padreCobrador : Modelo.Vendedores;

	detalleClientes : Modelo.Clientes[];
	detalleFidelizaciones : Modelo.Fidelizaciones[];
	detalleFideComunicaciones : Modelo.FideComunicaciones[];
	detalleTickets_como_ClienteOperacion : Modelo.Tickets[];
	detalleTickets_como_ClienteDeposito : Modelo.Tickets[];
	detalleFidePuntosExtras : Modelo.FidePuntosExtras[];
	detalleFidePuntosCuentaCorriente : Modelo.FidePuntosCuentaCorriente[];

}
export class _Depositos {
	depo_Codigo : number;
	sucu_Codigo : number;
	depo_CodigoAlfa : string;
	depo_Descripcion : string;
	depo_Almacen : string;
	depo_Tipo : string;
	depo_Activo : string;
	depo_CodDestino : number;
	depo_Provincia : string;
	depo_DWSistema : string;
	depo_DWImportaStock : string;
	depo_Clase : string;
	depo_Canal : string;
	depo_TipoSucursal : string;
	depo_Orden : number;
	depo_ReposAutoDesde : Date;
	depo_ReposAutoHasta : Date;
	depo_DistribucionInicial : string;
	
	padreSucursales : Modelo.Sucursales;

	detalleTickets_como_DepositoDelTicket : Modelo.Tickets[];
	detalleTickets_como_DepositoDelCliente : Modelo.Tickets[];

}
export class _Descuentos {
	desc_Codigo : number;
	desc_CodigoAlfa : string;
	desc_Descripcion : string;
	desc_Clasificacion : string;
	desc_FechaBaja : Date;
	

	detalleTickets : Modelo.Tickets[];

}
export class _ErroresDeProcesos {
	erro_Codigo : number;
	erro_Sistema : string;
	erro_Proceso : string;
	erro_Mensaje : string;
	erro_Objeto_Tabla : string;
	erro_Clave : string;
	


}
export class _FideBeneficios {
	fibe_Codigo : number;
	fpro_Codigo : number;
	fibe_CodigoAlfa : string;
	fibe_Descripcion : string;
	fibe_FechaDesde : Date;
	fibe_FechaHasta : Date;
	fibe_URL : string;
	fibe_Condiciones : string;
	fibe_Texto : string;
	fibe_Imagen_base64 : string
	
	padreFideProgramas : Modelo.FideProgramas;


}
export class _FideComunicaciones {
	fico_Id : number;
	fpro_Codigo : number;
	fide_Id : number;
	clie_Codigo : number;
	fico_FechaHora : Date;
	fico_Nombre : string;
	fico_email : string;
	fico_Mensaje : string;
	fico_Respuesta : string;
	fico_Estado : string;
	
	padreFidelizaciones : Modelo.Fidelizaciones;
	padreClientes : Modelo.Clientes;
	padreFideProgramas : Modelo.FideProgramas;


}
export class _Fidelizaciones {
	fide_Id : number;
	fpro_Codigo : number;
	clie_Codigo : number;
	sucu_Codigo : number;
	vend_Codigo : number;
	fide_TipoDoc : string;
	fide_NumeroDoc : number;
	fide_Sexo : string;
	fide_Apellido : string;
	fide_Nombre : string;
	fide_email : string;
	fide_TelMovil : string;
	fide_TelFijo : string;
	fide_Nacimiento : Date;
	pais_Codigo : string;
	fide_EstadoCivil : string;
	fide_DomiTipo : string;
	fide_DomiCalle : string;
	fide_DomiAltura : number;
	fide_DomiPiso : string;
	fide_DomiCuerpo : string;
	fide_DomiDepto : string;
	fide_DomiUF : string;
	fide_DomiManzana : string;
	fide_DomiLote : string;
	fide_DomiBarrio : string;
	fide_DomiEntreCalles : string;
	fide_DomiLocalidad : string;
	pcia_Codigo : string;
	fide_DomiPais : string;
	fide_DomiCodigoPostal : string;
	fide_DomiGLN : string;
	fide_Convivientes : number;
	fide_Ocupacion : string;
	fide_OcupacionOtros : string;
	fide_Competencia : string;
	fide_CompetenciaOtros : string;
	fide_MotivoCompra : string;
	fide_LifeStyle : string;
	fide_Estado : string;
	fide_FechaSolicitud : Date;
	fide_FechaAlta : Date;
	fide_FechaFinAlta : Date;
	fide_FechaEnvioMail : Date;
	fide_FechaRecordatorio : Date;
	fide_ErrorEnvioMail : string;
	fide_RecibeNovedades : string;
	fide_AceptaCondiciones : string;
	fide_ClaveAcceso : string;
	fide_Observaciones : string;
	
	padreVendedores : Modelo.Vendedores;
	padreSucursales : Modelo.Sucursales;
	padreFideProgramas : Modelo.FideProgramas;
	padreClientes : Modelo.Clientes;

	detalleFideComunicaciones : Modelo.FideComunicaciones[];

}
export class _FideProgramas {
	fpro_Codigo : number;
	marc_Codigo : number;
	fpro_Programa : string;
	fpro_VigenciaDesde : Date;
	fpro_VigenciaHasta : Date;
	
	padreMarcas : Modelo.Marcas;

	detalleFidelizaciones : Modelo.Fidelizaciones[];
	detalleFideComunicaciones : Modelo.FideComunicaciones[];
	detalleFideBeneficios : Modelo.FideBeneficios[];
	detalleFidePuntosConversion : Modelo.FidePuntosConversion[];
	detalleFidePuntosExtras : Modelo.FidePuntosExtras[];
	detalleFidePuntosCuentaCorriente : Modelo.FidePuntosCuentaCorriente[];

}
export class _FidePuntosConversion {
	fpco_Codigo : number;
	fpro_Codigo : number;
	fpco_FechaDesde : Date;
	fpco_FechaHasta : Date;
	fpco_Validez : Date;
	fpco_DiasVencimiento : number;
	fpco_Pesos : number;
	fpco_Puntos : number;
	
	padreFideProgramas : Modelo.FideProgramas;

	detalleFidePuntosExtras : Modelo.FidePuntosExtras[];
	detalleFidePuntosCuentaCorriente : Modelo.FidePuntosCuentaCorriente[];

}
export class _FidePuntosCuentaCorriente {
	fpcc_Id : number;
	fpcc_IdPadre : number;
	fpro_Codigo : number;
	clie_Codigo : number;
	sucu_Codigo : number;
	fpex_Id : number;
	fptm_Codigo : number;
	fpco_Codigo : number;
	fpcc_Transaccion : number;
	fpcc_Fecha : Date;
	fpcc_Operacion : string;
	fpcc_Prefijo : number;
	fpcc_Numero : number;
	fpcc_Importe : number;
	fpcc_Puntos : number;
	fpcc_SaldoPuntos : number;
	fpcc_Vencimiento : Date;
	fpcc_FechaProceso : Date;
	
	padreFidePuntosTiposMov : Modelo.FidePuntosTiposMov;
	padreFideProgramas : Modelo.FideProgramas;
	padreFidePuntosConversion : Modelo.FidePuntosConversion;
	padreSucursales : Modelo.Sucursales;
	padreClientes : Modelo.Clientes;
	padreFidePuntosCuentaCorriente : Modelo.FidePuntosCuentaCorriente;
	padreFidePuntosExtras : Modelo.FidePuntosExtras;

	detalleFidePuntosCuentaCorriente : Modelo.FidePuntosCuentaCorriente[];

}
export class _FidePuntosExtras {
	fpex_Id : number;
	fpro_Codigo : number;
	clie_Codigo : number;
	sucu_Codigo : number;
	fpco_Codigo : number;
	fpex_Fecha : string;
	fpex_Importe : number;
	fpex_Puntos : number;
	fpex_Estado : string;
	fpex_Motivo : string;
	
	padreFideProgramas : Modelo.FideProgramas;
	padreClientes : Modelo.Clientes;
	padreSucursales : Modelo.Sucursales;
	padreFidePuntosConversion : Modelo.FidePuntosConversion;

	detalleFidePuntosCuentaCorriente : Modelo.FidePuntosCuentaCorriente[];

}
export class _FidePuntosTiposMov {
	fptm_Codigo : number;
	fptm_Descipcion : string;
	fptm_Operacion : string;
	

	detalleFidePuntosCuentaCorriente : Modelo.FidePuntosCuentaCorriente[];

}
export class _FranjasHorarias {
	frah_Mes : number;
	tfrh_Codigo : number;
	frah_Desde : string;
	frah_Hasta : string;
	
	padreTipoFranjaHoraria : Modelo.TipoFranjaHoraria;


}
export class _Localidades {
	loca_Codigo : number;
	loca_Descripcion : string;
	loca_Partido : string;
	loca_Zona : string;
	loca_CodigoPostal : string;
	loca_Provincia : string;
	

	detalleClientes : Modelo.Clientes[];

}
export class _LogMultiproposito {
	logm_Id : number;
	logm_FechaHora : Date;
	logm_Tipo : string;
	logm_Sistema : string;
	logm_Formulario : string;
	logm_Evento : string;
	logm_Usuario : string;
	logm_Descripcion : string;
	logm_IP : string;
	logm_DataCliente : string;
	


}
export class _Marcas {
	marc_Codigo : number;
	marc_CodigoAlfa : string;
	marc_Nombre : string;
	marc_FechaBaja : Date;
	

	detalleSucursales : Modelo.Sucursales[];
	detalleFideProgramas : Modelo.FideProgramas[];
	detalleTickets : Modelo.Tickets[];

}
export class _PINes {
	pine_Codigo : number;
	pine_PIN : string;
	

	detalleClientes : Modelo.Clientes[];

}
export class _ProcesosRealizados {
	proc_Codigo : number;
	proc_FechaInicio : Date;
	proc_FechaFin : Date;
	proc_Proceso : string;
	proc_Sucursales : string;
	proc_Comentario : string;
	proc_Terminal : string;
	


}
export class _Recursos {
	iDrecurso : number;
	sistema : string;
	modulo : string;
	recurso : string;
	descripcion : string;
	nivel : number;
	

	detalleAccesos : Modelo.Accesos[];

}
export class _Sucursales {
	sucu_Codigo : number;
	sucu_CodigoMadre : number;
	marc_Codigo : number;
	sucu_CodigoAlfa : string;
	sucu_Descripcion : string;
	sucu_DescripcionLarga : string;
	sucu_ClaseDescrip : string;
	sucu_Clase : string;
	sucu_directorio : string;
	sucu_Canal : string;
	sucu_Tipo : string;
	sucu_Supervisor : string;
	sucu_Letra : string;
	sucu_Clasificacion : string;
	sucu_Datos : string;
	sucu_Proceso : string;
	sucu_Mayorista : string;
	sucu_OrigenDestino : string;
	sucu_M2 : number;
	sucu_Orden : number;
	sucu_FechaAlta : Date;
	sucu_FechaBaja : Date;
	sucu_ReposAutoDesde : Date;
	sucu_ReposAutoHasta : Date;
	sucu_DistribucionInicial : string;
	sucu_BloqueoCrediticio : string;
	sucu_Provincia : string;
	sucu_Zona : string;
	sucu_MinimaEntrega : number;
	sucu_TransportePropio : string;
	sucu_Agrupamiento1 : string;
	sucu_Agrupamiento2 : string;
	
	padreMarcas : Modelo.Marcas;
	padreSucursales : Modelo.Sucursales;

	detalleSucursales : Modelo.Sucursales[];
	detalleVendedores : Modelo.Vendedores[];
	detalleClientes_como_SucursalMasCompra : Modelo.Clientes[];
	detalleClientes_como_SucursalCompraActual : Modelo.Clientes[];
	detalleClientes_como_SucursalDelCliente : Modelo.Clientes[];
	detalleFidelizaciones : Modelo.Fidelizaciones[];
	detalleDepositos : Modelo.Depositos[];
	detalleTickets : Modelo.Tickets[];
	detalleFidePuntosExtras : Modelo.FidePuntosExtras[];
	detalleFidePuntosCuentaCorriente : Modelo.FidePuntosCuentaCorriente[];

}
export class _SysNumeraXAnio {
	nombreTabla : string;
	snxa_Anio : number;
	snxa_NumeroId : number;
	
	padreSystemIDs : Modelo.SystemIDs;


}
export class _SystemIDs {
	nombreTabla : string;
	numeroId : number;
	insertLog : number;
	deleteLog : number;
	updateLog : number;
	

	detalleSysNumeraXAnio : Modelo.SysNumeraXAnio[];

}
export class _Tickets {
	tick_Id : number;
	sucu_Codigo : number;
	depo_Codigo : number;
	depo_CodigoDelCliente : number;
	tick_Numero : number;
	tick_TransOrigen : string;
	tick_Transaccion : number;
	fechaVenta : Date;
	fechaImputacion : Date;
	marc_Codigo : number;
	vend_Codigo : number;
	clie_Codigo : number;
	clie_CodigoDelDeposito : number;
	desc_Codigo : number;
	tfrh_Codigo : number;
	tick_FA_NC : string;
	tick_Letra : string;
	tick_Origen : string;
	tick_PuntoDeVenta : string;
	mesVenta : Date;
	tick_Gravado : number;
	tick_DescuPie : number;
	tick_DescuLinea : number;
	tick_Recargo : number;
	tick_IVA : number;
	tick_PercepcionIB1 : number;
	tick_PercepcionIB2 : number;
	tick_PercepcionIB3 : number;
	tick_PercepcionIB4 : number;
	tick_PercepcionGan : number;
	tick_SesionCredito : number;
	tick_ComisionConsi : number;
	tick_AlicuotaIVAconsi : number;
	tick_Comprobante : number;
	tick_CompProductividad : number;
	tick_CantArticulos : number;
	tick_CantArtStockeables : number;
	tick_AdhesionesFidel : number;
	tick_ComprobanteRem : number;
	tick_CantArticulosRem : number;
	tick_GravadoRem : number;
	tick_IVARem : number;
	tick_Observaciones : string;
	tick_FechaProceso : Date;
	
	padreMarcas : Modelo.Marcas;
	padreVendedores : Modelo.Vendedores;
	padreSucursales : Modelo.Sucursales;
	padreClienteOperacion : Modelo.Clientes;
	padreDescuentos : Modelo.Descuentos;
	padreTipoFranjaHoraria : Modelo.TipoFranjaHoraria;
	padreDepositoDelTicket : Modelo.Depositos;
	padreDepositoDelCliente : Modelo.Depositos;
	padreClienteDeposito : Modelo.Clientes;


}
export class _TipoFranjaHoraria {
	tfrh_Codigo : number;
	tfrh_Descripcion : string;
	

	detalleTickets : Modelo.Tickets[];
	detalleFranjasHorarias : Modelo.FranjasHorarias[];

}
export class _Usuarios {
	identificacion : string;
	usua_Nombre : string;
	clave : string;
	nivel : number;
	modelo : string;
	fechaBaja : Date;
	email : string;
	

	detalleAccesos : Modelo.Accesos[];

}
export class _Vendedores {
	vend_Codigo : number;
	vend_CodigoAlfa : string;
	vend_Nombre : string;
	sucu_Codigo : number;
	vend_Estado : string;
	vend_Funcion : string;
	vend_Legajo : string;
	
	padreSucursales : Modelo.Sucursales;

	detalleClientes_como_Vendedor : Modelo.Clientes[];
	detalleClientes_como_Cobrador : Modelo.Clientes[];
	detalleFidelizaciones : Modelo.Fidelizaciones[];
	detalleTickets : Modelo.Tickets[];

}
