import { NgModule } from '@angular/core';
import { UpperCaseDirective } from '@app/capitalizar.directive';
import { SharedModule } from '@app/shared';
import { DialogoConfirmacionComponent } from '@app/shared/dialogo-confirmacion/dialogo-confirmacion';
import { ContactoComponent } from './contacto/contacto.component';
import { LoginComponent } from './login/login.component';
import { ComprasComponent } from './miembros/compras/compras.component';
import { CuponesComponent } from './miembros/cupones/cupones.component';
import { CuponDialogComponent } from './miembros/cupones/cupon_dialog/cupon_dialog.component';
import { CuponItemComponent } from './miembros/cupones/cupon_item/cupon_item.component';
import { PerfilComponent } from './miembros/perfil/perfil.component';
import { PuntosComponent } from './miembros/puntos/puntos.component';
import { TarjetaComponent } from './miembros/tarjeta/tarjeta.component';
import { VencimientosComponent } from './miembros/vencimientos/vencimientos.component';
import { RegistrarComponent } from './registrar/registrar.component';
import { StaticRoutingModule } from './static-routing.module';



@NgModule({
  imports: [SharedModule, StaticRoutingModule],
  declarations: [RegistrarComponent, UpperCaseDirective,LoginComponent,
     ComprasComponent, PuntosComponent, TarjetaComponent, PerfilComponent, 
     ContactoComponent, VencimientosComponent, CuponesComponent, CuponItemComponent, CuponDialogComponent, DialogoConfirmacionComponent
    
    ],
    entryComponents: [ DialogoConfirmacionComponent, CuponDialogComponent]

})
export class StaticModule {}
