import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';

import { ROUTE_ANIMATIONS_ELEMENTS, NotificationService } from '@app/core';
import { auxLoginSignup, MainService } from '@app/core/main.service';

@Component({
  selector: 'fidelizacion-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactoComponent implements OnInit {
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  nuevo: auxLoginSignup = new auxLoginSignup();


  form = this.fb.group({
    autosave: false,
    nombre: ['', [Validators.required]],
    consulta: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
  });


  constructor(private fb: FormBuilder, public asm: MainService, private noti: NotificationService, private cd: ChangeDetectorRef
  ) { }


  enviado: boolean = false;
  ngOnInit() {
    this.form.get('nombre').setValue(this.asm.usuario ? this.asm.usuario.fide_Nombre : '');
    this.form.get('email').setValue(this.asm.usuario ? this.asm.usuario.fide_email : '');
    if (this.asm.usuario) {
      this.form.get('nombre').disable();
      this.form.get('email').disable();
    }
  }
  contacto() {

    this.asm.post("Contacto", {
      email: this.form.get('email').value,
      nombre: this.form.get('nombre').value,
      consulta: this.form.get('consulta').value,
	 programa : this.asm.programa
	}).subscribe((ret) => {
      this.enviado = true;
      this.cd.markForCheck();
    },
      (err) => {
        this.cd.markForCheck();
        this.asm.requesting--;
        if (err.error && err.error.Mensaje)
          this.noti.warn(err.error.Mensaje);
      }
    )
  }
}
